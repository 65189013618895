import IconFont from 'src/ui-kit/IconFont';
import { useTheme } from 'src/ui-kit/theme';
import { getStyles } from './NavButtons.styles';

export interface Controls {
  onPrevSlide: () => void;
  onNextSlide: () => void;
  sliderState: {
    isEndPositionLeft: boolean;
    isEndPositionRight: boolean;
  };
}

interface NavButtonsProps {
  controls: Controls;
  dataMarker?: string;
}

const NavButtons = (props: NavButtonsProps) => {
  const { dataMarker, controls: { onPrevSlide, onNextSlide, sliderState } } = props;
  const { isEndPositionLeft, isEndPositionRight } = sliderState;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div className='NavButtons' data-marker={dataMarker}>
      <button
        type='button'
        className='NavButtons__button'
        data-marker='Previous slide'
        data-testid='prev-button'
        onClick={onPrevSlide}
        disabled={isEndPositionLeft}
      >
        <IconFont name='arrow-small-left' />
      </button>
      <button
        type='button'
        className='NavButtons__button'
        data-marker='Next slide'
        data-testid='next-button'
        onClick={onNextSlide}
        disabled={isEndPositionRight}
      >
        <IconFont name='arrow-small-right' />
      </button>
      <style jsx>{styles}</style>
    </div>
  );
};

export default NavButtons;
