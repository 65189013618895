import { useRouter } from "next/router";
import { BASE_URL } from "src/data/constants";


const useCanonicalHref = (): string => {
  const { asPath } = useRouter();
  const [pathWithoutParams, getParams] = asPath.split('/?');

  const isPagination = getParams?.includes('page=');

  if(isPagination) {
    const params = new URLSearchParams(getParams);
    const pageValue = params.get('page');
    return BASE_URL + pathWithoutParams + `/?page=${pageValue}`;
  }

  return BASE_URL + pathWithoutParams;
};

export default useCanonicalHref;
