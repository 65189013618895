import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    .NavButtons {
      display: flex;
      flex: 0 0 auto;
    }
    .NavButtons__button {
      margin-left: 8px;
      width: 40px;
      height: 40px;
      background-color: transparent;
      border: ${theme.base.border.v1} ${theme.base.color.gray300};
      border-radius: ${theme.base.borderRadii.v1};
      outline: none;
      font-size: 10px;
      line-height: 1;
      cursor: pointer;
      transition: border-color 0.2s ease-out;
    }
    .NavButtons__button:hover:not(:disabled) {
      border-color: ${theme.base.color.primary};
    }
    .NavButtons__button:disabled {
      color: ${theme.base.color.gray400};
      cursor: default;
    }
  `;
};
